import React from 'react';
import '../../Styles/MainStyles.css';
import { useLocation, Link } from 'react-router-dom';

export default function UserDeletedPage() {
  const location = useLocation();
  const message = new URLSearchParams(location.search).get('message');
  return (
    <main>
      <div className="wrapper">
        {message && <h4 className="response">{message}</h4>}
        <Link to="/cleanup" className="link-button">
          Вернуться на главную
        </Link>
      </div>
    </main>
  );
}
