import React, { useState } from 'react';
import '../../Styles/MainStyles.css';

export default function HomePage() {
  const [input, setInput] = useState({
    email: '',
  });
  const [answer, setAnswer] = useState('Введите email от мобильного приложения Turkov');
  const [isLoading, setIsLoading] = useState(false);
  const backendURLProdServer = 'https://account.turkov.ru/api/cleanup/';
  const changeHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const submitHandler = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const response = await fetch(backendURLProdServer, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(input),
        credentials: 'include',
      });
      setIsLoading(false);
      if (response.ok) {
        const data = await response.json();
        setAnswer(data.message);
      } else {
        const error = await response.json();
        setAnswer(error.error);
      }
    } catch (error) {
      setAnswer(error.message);
    }
  };
  return (
    <main>
      <div className="wrapper">
        <h1>Удаление аккаунта</h1>
        {answer ? <h4 className="response">{answer}</h4> : ''}
        <form id="form-post" onSubmit={submitHandler}>
          <input
            id="user"
            type="email"
            name="email"
            title="Ваш email от мобильного приложения"
            value={input.email}
            onChange={changeHandler}
            placeholder="Логин (электронная почта)"
            required
          />
          {isLoading
            ? (<div className="spinner" />)
            : (<button type="submit" disabled={isLoading}>Отправить</button>)}
        </form>
      </div>
    </main>
  );
}
