import React from 'react';
import '../../Styles/MainStyles.css';

export default function NotFoundPage() {
  return (
    <main>
      <div className="wrapper">
        <h4 className="response"> Запрашиваемая страница не найдена.</h4>
      </div>
    </main>
  );
}
