import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Components/Pages/HomePage';
import UserDeletedPage from './Components/Pages/UserDeletedPage';
import NotFoundPage from './Components/Pages/NotFoundPage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/cleanup" element={<HomePage />} />
        <Route path="/cleanup/user-deleted-page" element={<UserDeletedPage />} />
        <Route path="*" exact element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
